<template>
    <div class="flex">
        <div>
        <el-form
            :model="Form"
            :rules="rules"
            ref="ruleForm"
            label-width="120px"
            v-loading="loading"
        >
            <el-row :gutter="30">
                <el-col :span="12">
                    <el-form-item label="登录账户:" prop="account">
                        <el-input v-model="Form.account" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="登录密码:" prop="pwd">
                        <el-input v-model="Form.pwd" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="数字通证名称:" prop="tokenName">
                        <el-input v-model="Form.tokenName" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="数字通证英文名称:" prop="tokenEnglish">
                        <el-input v-model="Form.tokenEnglish" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="数字通证编号:" prop="tokenNum">
                        <el-input v-model="Form.tokenNum" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="通证转出:" prop="transferTime">
                        <el-input v-model="Form.transferTime" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="归集账户地址:" prop="wallet">
                        <el-input v-model="Form.wallet" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="协议区价格:" prop="agreementPrice">
                        <el-input v-model="Form.agreementPrice" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="原价区价格:" prop="price">
                        <el-input v-model="Form.price" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="退回钱包天数" prop="backTime">
                        <el-input v-model="Form.backTime" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="扣除差价百分比:" prop="rate">
                        <el-input v-model="Form.rate" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="锁仓天数:" prop="rockTime">
                        <el-input v-model="Form.rockTime" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="公钥:" prop="secret">
                        <el-input v-model="Form.secret" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="商城流入百分比:" prop="shopRate">
                        <el-input v-model="Form.shopRate" ></el-input>
                    </el-form-item>
                </el-col>
                 <el-col :span="12">
                    <el-form-item label="状态:" prop="status">
                        <el-radio-group v-model="Form.status">
                            <el-radio :label="0">上线</el-radio>
                            <el-radio :label="1">下线</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="">
         <el-button type="primary" style="margin-left: auto" @click='submit()' >提交</el-button>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            Form:{},
            rules:{},
            loading:false,
            id:"",
        }
    },
    mounted(){
        if(this.$route.query.id){
            this.id=this.$route.query.id
            this.getInfo()
        }
        
    },
    methods:{
        getInfo(){
            this.axios.get('/admin/gen/findById',{id:this.id}).then(res=>{
                this.Form=res.data.data
            })
        },
        submit(){
            this.loading=true
            if(this.id){
                this.axios.put('/admin/gen/updateGen',this.Form).then(res=>{
                    this.loading=false
                    if(res.code==200){
                            this.$router.push('gen')
                        }else{
                            this.$message(res.mesg)
                        }
                })
            }else{
                
                this.axios.post('/admin/gen/addGen',this.Form)
                .then(res=>{
                        this.loading=false
                        if(res.data.code==200){
                            this.$router.push('gen')
                        }else{
                            this.$message(res.data.mesg)
                        }
                })
            }
            
        },
    }
}


</script>

<style lang="scss" scoped>
.flex {
  padding: 50px;
  width: 1000px;
  display: flex;
  // background-color: red;
  height: 200px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border-radius: 5px;
  border: 1px dashed #8c939d;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
  border-radius: 5px;
}
</style>